.activestatus{
    color: #ffffff;
    border-radius: 5px;
    background: #13845d;
    padding:5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  #active_dot{
    width: 8px;
height: 8px;
border-radius: 10px;
background-color: #03de73;
  }
  .inactivestatus{
    color: #FFFFFF;
    border-radius: 5px;
    background: #863d54;
    padding:5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  .startedbysomeone{
    color: #FFFFFF;
    border-radius: 5px;
    background: #1C5393;
    padding:5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  .startedbysomeoneNew{
    color: #FFFFFF;
    border-radius: 5px;
    background: #1C5393;
    padding:5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    /* gap: 4px; */
  }
  .inprogressstatus{
    color: #ffffff;
    border-radius: 5px;
    background: #db7608;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  #progress_dot{
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #ffaa22;
  }
  #someone_dot{
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: blue;
  }
  #inactive_dot{
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #e95060;
  }
  .blockedstatus{
    color: #F16A87;
  }