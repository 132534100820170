.sidebar {
  width: 171pt;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--secondary-color);
  padding: 0 0 0 2em;
  /* transition: var(--transition1); */
  height: 90vh;
  /* overflow-y: hidden; */
  overflow-x: auto;
}
.sidebar:hover,
.sidebar:active {
  overflow-y: auto;
}

.sb-collapsed {
  width: var(--sidebar-width-collapsed);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--secondary-color);
  transition: var(--transition1);
}

.sidebar__top {
  width: 100%;
}

.sidebar__top--collapsed {
  display: flex;
  justify-content: center;
}

.logo-collapsed {
  width: 3.8em;
  object-fit: contain;
  height: auto;
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.sidebar__bottom {
  bottom: 0;
  left: 2.5em;
  margin-bottom: 5px;
}

.nav__link,
.sidebar__bottom span {
  display: flex;
  align-items: center;
  column-gap: 1em !important;
  cursor: pointer;
  transition: 0.3s;
  color: var(--white-color) !important;
  font-style: normal;
  font-weight: 300;
  font-size: 0.9em;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}

.nav__list {
  display: flex;
  /* row-gap: 1em; */
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6em;
  transition: var(--transition1);
}

.nav__list--collapsed {
  align-items: center !important;
  row-gap: 0.5em !important;
}

.nav__active {
  border-right: 0.1em solid var(--active-color);
  color: var(--active-color) !important;
  transition: 1ms;
}

.nav__active--collapsed {
  color: var(--active-color) !important;
}

.menu > ul {
  padding-left: 0 !important;
}

.nav__item--text {
  margin-left: 1em;
}

.nav__list li {
  margin-bottom: 1em;
}
.exit_btns_container {
  font-size: 12px !important;
}

.exit_btns {
  width: 72%;
  background: #18a3a0;
  text-align: center;
  padding: 3%;
  border-radius: 4px;
  cursor: pointer;
}

/* .modal-content {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
.modal-header {
  border-bottom-width: 0px !important;
  margin-top: 1%;
  margin-left: 3%;
  margin-right: 2%;
  border-bottom: none;
} */
