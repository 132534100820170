.header-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 2px;
    margin-right: 30px;
    width: 98%;
    border-radius: 6px;

}

.header-container-chart {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-top: 10px;
    margin-right: 30px;
    background-color: #252b48;
    width: 100%;
    

}

.button-Charts {
    margin-left: auto; /* Push the button to the right */
    margin-right: 10px; /* Add spacing from the edge */
}

.toggle-container {
    margin-left: auto; /* Aligns toggle container to the right */
}

.header-container p {
    margin: 0;
    padding: 2px;
}

.p-data{
    margin-left: 2px;
}
.table-container {
    height: 350px; /* Set a fixed height for the container */
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative;
    background-color: #252b48;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    padding: 3px;
    text-align: center;
}

table th {
    color: white;
    text-align: center;
    padding: 8px; /* Adjust padding for better alignment */
    position: sticky; /* Make header sticky */
    top: 0; /* Position it at the top of the scrolling container */
    z-index: 1; /* Ensure it stays above other content */
}
.table-container > table > tbody > tr > td {
    white-space: nowrap; /* Prevent text wrapping in outer table */
}

.table-container .expanded-content .inner-table td {
    white-space: normal; /* Allow text wrapping in inner table */
}

.table-container > table > th {
    white-space: nowrap; /* Prevent header text wrapping in outer table */
}
.table-container .expanded-content .inner-table th {
    position: sticky; /* Keeps the inner table header sticky */
    top: 0; /* Stick the header to the top of the inner container */
    z-index: 2; /* Set a z-index lower than the outer table header */
    background-color: #62A8FE; /* Ensure it matches the inner table background */
    white-space: nowrap; /* Prevent text wrapping */
}

.table-container > table th {
    position: sticky; /* Keeps the outer table header sticky */
    top: 0;
    z-index: 3; /* Set a higher z-index for the outer table header */
    background-color: #252b48; /* Matches the outer table background */
    color: white; /* Ensure proper contrast */
}




table th:first-child {
    text-align: left; /* Center align the text in the first column */
    white-space: nowrap; /* Ensure "S NO" stays on one line */
}


.dark-blue-row {
    background-color: #252c48; /* Dark blue */
    color: white;
}

.light-blue-row {
    background-color: #393f5f; /* Light blue */
    color: white;
}

.legend-table {
    width: 100%;
    margin-top: 20px;
}

.legend-table td {
    text-align: center;
    padding: 15px;
    font-weight: bold;
    color: white;
}

.legend {
    background-color: #fef5cd; /* Color for Moderate Rain */
}

.moderate-rain-box {
    background-color: #c6ecc6; /* Color for Moderate Rain */
}

.heavy-rain-box {
    background-color: #66b0ff; /* Color for Heavy Rain */
}

.very-heavy-rain-box {
    background-color: #6870e3; /* Color for Very Heavy Rain */
}

.content-container{
    margin-top: 5px;
}

.inner-table{
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative;
    width: 100%; /* Ensures the table takes up the full width of the container */
    border-collapse: collapse; /* Ensures borders don't double up */
    margin: 0 auto; /* Center the table */
}

.inner-table th{
    background-color: #62A8FE;
    border-collapse: collapse; /* Ensures borders don't double up */
    overflow-y: auto; /* Enable vertical scrolling */
    position:static;
}
/* 
.table-container > table > tbody > tr:hover {
    background-color: #3f71b6;
    cursor: pointer;
} */


.block-details-container {
    width: 95%; /* Adjust the width as needed */
    border: 2px solid #62A8FE; /* Adds a thick blue border */
    background-color: #252C48; /* Matches the background color */
    margin: 0 auto; /* Centers the container horizontally */
    display: block; /* Ensures it's treated as a block-level element */
}


.hs-table-head {
    border: none;
    padding: 5px;
    background-color: #23b1a4;
    text-align: left;
    position: unset;
    
}
.hs-table-radius-left{
    border-top-left-radius: 12px;
}
.hs-table-radius-right{
    border-top-right-radius: 12px;
}
.hs-table-tr{
    background-color: #1c5393 !important;
}
.hs-table-td{
    border-top: 1px solid #d9e1e733;
    border-bottom: 1px solid #d9e1e733;
    padding: 5px;
    cursor: pointer;
    font-size: 13px;
    text-align: left;
}
.hs-table-td-footer{
        border: none;
        padding: 10px;
        background-color: #252b48;
        color: white;
        font-size: 13px;
        margin: 0rem;
        position:sticky;
        text-align: left;
}
.hs-table-footer-left{
    border-bottom-left-radius: 12px;
}
.hs-table-footer-right{
    border-bottom-right-radius: 12px;
}
.hl-table-div{
    border-radius: 12px;
    border: 1px solid #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}
.hl-table{
    border-radius: 12px;
}
.h1-table-child{
    /* width: 85%; */
    border-collapse: collapse;
    margin-top: 0px;
    margin-left: 0px;
    /* margin-right: 20px; */
    margin-bottom: 0px;
    text-align: center;
    max-height: 100px;
    overflow-y: auto;
}
.hl-table-child-div{
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 12px;
    border: 1px solid #fff;
    margin-top: 10px;
    margin-bottom: 10px;
  
}

.doughnut-container {
    display: flex;
    justify-content: space-between; /* Ensures even spacing */
    gap: 10px; /* Space between charts */
    width: 100%;
    margin-bottom: 10px;
    height: 180pt;
  }
  
  .doughnut-item {
    flex: 1; /* Equal width for all charts */
    max-width: 600px; /* Optional: Adjust width as needed */
  }
  

